import React from "react";
import { connect } from "react-redux";
import { Table, Button, PageHeader, Col, Row, Select, Form, DatePicker, Modal, Input, Descriptions } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "react-router-dom"
import { RootState } from "@app/reducers";
import { bindActionCreators, Dispatch } from "redux";
import { getBrokerListAction, getBrokerSearchByAccountAction } from "@app/actions/broker_action";
import { FormatDate } from "@app/components";
import { filterAdvancedTable, filterBasicTable, sortTable } from "@app/utils";
import moment from "moment";
import { validRender } from "@app/constants/list";

const mapStateToProps = (state: RootState) => ({
    broker: state.brokerReducer
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    dispatch,
    ...bindActionCreators(
        {
            getBrokerListAction: getBrokerListAction,
            getBrokerSearchByAccountAction: getBrokerSearchByAccountAction
        },
        dispatch
    )
});

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;

const COMPANY_DATA = [
    { value: "gainup", label: "Gainup" },
    { value: "brazil", label: "Tradestars - Brasil" },
    { value: "latam", label: "Tradestars - LATAM" }
]

const TYPEDATE_DATA = [
    { value: "month", label: "Mês Atual" },
    { value: "lastMonth", label: "Último Mês" },
    { value: "threeMonths", label: "3 Meses" },
    { value: "", label: "Personalizado" }
]

const AffiliationScreen: React.FC<Props> = (props) => {
    const [disabledDate, setDisabledDate] = React.useState(true);
    const [form] = Form.useForm();
    const [formFaster] = Form.useForm();
    const { broker, getBrokerListAction, getBrokerSearchByAccountAction } = props;

    console.log(props)

    React.useEffect(() => {
        const { data } = broker.data;
        if (data) {
            Modal.info({
                title: "Consulta individual de Conta",
                content: (
                    <Descriptions column={1} style={{ marginTop: 20 }}>
                        <Descriptions.Item label="Empresa">{data.company.toUpperCase()}</Descriptions.Item>
                        <Descriptions.Item label="Corretora">{data.broker}</Descriptions.Item>
                        <Descriptions.Item label="Campanha">{data.campaign}</Descriptions.Item>
                        <Descriptions.Item label="Servidor">{data.server}</Descriptions.Item>
                        <Descriptions.Item label="Tipo">{data.type}</Descriptions.Item>
                        <Descriptions.Item label="Status">{validRender.render(data.valid)}</Descriptions.Item>
                    </Descriptions>
                ),
                onOk() {
                    props.dispatch({ type: "GET_BROKER_ACCOUNT_CLEAR" })
                }
            });
        }
    }, [broker.data])

    const onPeriodChange = (value: string) => {
        let start = moment().startOf("month");
        let end = moment().endOf("month");
        if (value === "lastMonth") {
            start = start.subtract(1, "month")
            end = end.subtract(1, "month").endOf("month")
        } else if (value === "threeMonths") {
            start = start.subtract(3, "month")
        }
        form.setFieldsValue({ date: [start, end] });
        setDisabledDate(value === "" ? false : true);
    }

    const onFinish = (e: any) => {
        getBrokerListAction(e.company, e.date[0].format("YYYY-MM-DD"), e.date[1].format("YYYY-MM-DD"));
    }

    const onFinishFaster = (e: any) => {
        getBrokerSearchByAccountAction(e.account);
    }

    return (
        <PageHeader ghost={false} title="Consulta de Conta">
            <Form form={formFaster}
                  layout="horizontal"
                  onFinish={onFinishFaster}>
                <Row gutter={24}>
                    <Col xl={4} lg={6} md={12}>
                        <Form.Item name="account" rules={[{ required: true, message: "Campo obrigatório" }]}>
                            <Input placeholder={"Número da Conta"} />
                        </Form.Item>
                    </Col>
                    <Col xl={4} lg={6} md={12}>
                        <Form.Item>
                            <Button loading={broker.data.isProcessing} block htmlType={"submit"} type="primary" icon={<SearchOutlined />}>Pesquisa Rápida</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Form form={form}
                  layout="horizontal"
                  initialValues={{ period: "month", date: [moment().startOf("month"), moment().endOf("month")] }}
                  onChange={(values) => console.log(values)}
                  onFinish={onFinish}>
                <Row gutter={24}>
                    <Col xl={5} lg={12} md={12}>
                        <Form.Item name="company" rules={[{ required: true, message: "Campo obrigatório" }]}>
                            <Select options={COMPANY_DATA} placeholder={"Selecione uma empresa..."} />
                        </Form.Item>
                    </Col>
                    <Col xl={5} lg={12} md={12}>
                        <Form.Item name="period">
                            <Select options={TYPEDATE_DATA} placeholder={"Selecione um período..."} onChange={onPeriodChange} />
                        </Form.Item>
                    </Col>
                    <Col xl={5} lg={12} md={12}>
                        <Form.Item name="date">
                            <DatePicker.RangePicker disabled={disabledDate} format={"DD/MM/YYYY"} style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xl={4} lg={8} md={12}>
                        <Form.Item>
                            <Button loading={broker.list.isProcessing} block htmlType="submit" type="primary" icon={<SearchOutlined />}>Pesquisar</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <Table loading={broker.list.isProcessing} size="small" dataSource={broker.list.list} pagination={false} bordered rowKey="accoundId" scroll={{ x: 1000 }}>
                                <Table.Column width={100} title="Status" dataIndex="valid" {...filterBasicTable("valid", validRender.list)}
                              render={validRender.render}
                />
                <Table.Column width={200} title="Primeira Conta" dataIndex="firstAccountId" {...filterAdvancedTable("firstAccountId")} />
                <Table.Column width={200} title="ID Conta" dataIndex="accountId" {...filterAdvancedTable("accountId")} />
                <Table.Column width={100} title="Ativação" dataIndex="activationDate" {...sortTable("activationDate")}
                              render={(data) => <FormatDate>{data}</FormatDate>}
                />
                <Table.Column defaultSortOrder={"ascend"} width={100} title="Cadastro"
                              dataIndex="signUpDate" {...sortTable("signUpDate")}
                              render={(data) => <FormatDate>{data}</FormatDate>}
                />
                <Table.Column width={100} title="Plataforma" dataIndex="platform" />
                <Table.Column width={150} title="País" dataIndex="country" {...filterAdvancedTable("country")} />
                <Table.Column title="Tipo" dataIndex="type" />
                <Table.Column title="Campanha" dataIndex="campaign" {...filterAdvancedTable("campaign")} />
            </Table>
        </PageHeader>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliationScreen);
