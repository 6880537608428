import React from "react";
import moment from "moment";

interface Props {
    format?: string
}

const FormatDate: React.FC<Props> = (props) => {
    const { format } = props;
    return (
        <>
            {props.children ? moment(props.children.toString()).format(format) : ""}
        </>
    )
};

FormatDate.defaultProps = {
    format: "DD/MM/YYYY"
};

export { FormatDate };