import React from "react";
import { Tag } from "antd";

const connectedList = [
    { color: "green", value: true, text: "CONECTADO" },
    { color: "red", value: false, text: "DESCONECTADO" }
]
export const connectedRender = {
    render: (data: any) => connectedList.filter(x => x.value === data).map(x => <Tag color={x.color}>{x.text}</Tag>),
    list: connectedList
}

const appList = [
    { color: "green", value: false, text: "SIM" },
    { color: "red", value: true, text: "NÃO" }
]
export const appRender = {
    render: (data: any) => appList.filter(x => x.value === data).map(x => <Tag color={x.color}>{x.text}</Tag>),
    list: appList
}

const enabledList = [
    { color: "green", value: true, text: "HABILITADO" },
    { color: "red", value: false, text: "DESABILITADO" }
]
export const enabledRender = {
    render: (data: any) => enabledList.filter(x => x.value === data).map(x => <Tag color={x.color}>{x.text}</Tag>),
    list: enabledList
}

const validList = [
    { color: "green", value: true, text: "VALIDADA" },
    { color: "red", value: false, text: "NÃO VALIDADA" }
]
export const validRender = {
    render: (data: any) => validList.filter(x => x.value === data).map(x => <Tag color={x.color}>{x.text}</Tag>),
    list: validList
}