import React, { useMemo } from "react";
import { bindActionCreators, Dispatch } from "redux";
import { connect } from "react-redux";
import { RootState } from "@app/reducers";
import { Form, Input, Button, Card, Row, Col, Layout } from "antd";
import { UserOutlined, LockFilled } from "@ant-design/icons";
import { Logo } from "@app/components";
import { loginAction, logoutAction } from "@app/actions/login_action";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (state: RootState) => ({
    login: state.loginReducer
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            loginAction: loginAction,
            logoutAction: logoutAction
        },
        dispatch
    );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;

const LoginScreen: React.FC<Props> = (props) => {
    const { loginAction, logoutAction, login, history } = props;

    React.useEffect(() => {
        logoutAction();
    }, [logoutAction])

    React.useEffect(() => {
        if (login.isLogged) {
            history.push("/");
        }
    }, [login, history])

    const onLogin = (values: { email: string, password: string }) => {
        loginAction(values.email, values.password);
    }

    const TitleHeader = useMemo(() => (
        <Row justify="center" align="middle">
            <Col>
                <Logo style={{ textAlign: "center" }} />
            </Col>
        </Row>
    ), []);

    return (
        <Layout style={{ height: "100vh", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "row" }}>
            <Layout.Content>
                <Row justify="center" align="middle">
                    <Col span={5}>
                        <Card title={TitleHeader} bordered={false}>
                            <Form style={{ width: "100%" }} name="basic" initialValues={{ email: "", password: "" }} onFinish={onLogin}>
                                <Form.Item
                                    hasFeedback
                                    name="email"
                                    rules={[{ required: true, type: "email", message: "Insira um email válido" }]}>
                                    <Input autoComplete="off" size="large" prefix={<UserOutlined />} inputMode="email" placeholder="Email" />
                                </Form.Item>
                                <Form.Item
                                    hasFeedback
                                    name="password"
                                    rules={[{ required: true, message: "Insira sua senha" }]}>
                                    <Input.Password size="large" prefix={<LockFilled />} placeholder="Senha" />
                                </Form.Item>
                                <Form.Item>
                                    <Button loading={login.isProcessing} size="large" type="primary" htmlType="submit" style={{ width: "100%" }}>Acessar</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
