import React from "react";
import { Table, Typography } from "antd";
import { FormatDate, FormatNumber } from "@app/components";

interface Props {
    dataSource: any[],
    type: "position" | "history",
    loading: boolean
}

const columns = [
    {
        width: 120,
        title: "Ticket",
        dataIndex: "ticket",
        key: "ticket",
        fixed: "left" as const,
        type: ["position", "history"]
    },
    {
        width: 150,
        title: "Trader",
        dataIndex: "trader",
        type: ["position", "history"]
    },
    {
        width: 120,
        title: "Ativo",
        dataIndex: "symbol",
        type: ["position", "history"]
    },
    {
        width: 80,
        title: "Tipo",
        dataIndex: "type",
        type: ["position", "history"]
    },
    {
        width: 80,
        align: "right" as const,
        title: "Volume",
        dataIndex: "volume",
        render: (data: any) => <FormatNumber>{data}</FormatNumber>,
        type: ["position", "history"]
    },
    {
        width: 120,
        align: "right" as const,
        title: "TP",
        dataIndex: "tp",
        type: ["position", "history"]
    },
    {
        width: 120,
        align: "right" as const,
        title: "SL",
        dataIndex: "sl",
        type: ["position", "history"]
    },
    {
        width: 180,
        align: "right" as const,
        title: "Abertura",
        dataIndex: "openedAt",
        render: (data: any) => <FormatDate format={"DD/MM/YYYY HH:mm:ss"}>{data}</FormatDate>,
        type: ["position", "history"]
    },
    {
        width: 150,
        align: "right" as const,
        title: "Preço Abertura",
        dataIndex: "openedPrice",
        type: ["position", "history"]
    },
    {
        width: 180,
        align: "right" as const,
        title: "Fechado",
        dataIndex: "closedAt",
        render: (data: any) => <FormatDate format={"DD/MM/YYYY HH:mm:ss"}>{data}</FormatDate>,
        type: ["history"]
    },
    {
        width: 150,
        align: "right" as const,
        title: "Preço Fechado",
        dataIndex: "closedPrice",
        type: ["history"]
    },
    {
        width: 150,
        align: "right" as const,
        fixed: "right" as const,
        title: "Lucro",
        render: (row: any) => <FormatNumber currency={row.currency}>{row.profit}</FormatNumber>,
        type: ["history"]
    }
]

const Orders: React.FC<Props> = (props) => {
    const { dataSource, type, loading } = props;

    return (
        <Table size="small" loading={loading} columns={columns.filter(x => x.type.indexOf(type) != -1)}
               dataSource={dataSource}
               pagination={false}
               scroll={{ y: 500, x: type == "position" ? 0 : 1600 }}
        />
    )
}

Orders.defaultProps = {
    dataSource: [],
    loading: false
}

export { Orders };