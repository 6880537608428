import React from "react";
import { Button, Input, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { FilterDropdownProps } from "antd/lib/table/interface";

export const sortTable = (dataIndex: string) => ({
    sorter: (a: any, b: any) => {
        if (!a[dataIndex]) return -1;
        if (!b[dataIndex]) return +1;
        return a[dataIndex].localeCompare(b[dataIndex])
    },
    sortDirections: ["descend" as const, "ascend" as const]
})

export const filterBasicTable = (dataIndex: string, list: any[]) => ({
    filters: list,
    onFilter: (value: any, row: any) => row[dataIndex] === value,
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1DA57A" : undefined }} />
})

export const filterAdvancedTable = (dataIndex: string) => {
    let searchInput: any = null;
    return ({
        filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1DA57A" : undefined }} />,
        filterDropdown: (e: FilterDropdownProps) => {
            const { setSelectedKeys, selectedKeys, confirm, clearFilters } = e;
            return (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => {
                            searchInput = node;
                        }}
                        placeholder={"Pesquisar..."}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => confirm()}
                        style={{ width: 200, marginBottom: 8, display: "block" }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => confirm()}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 100 }}
                        >Pesquisar</Button>
                        <Button onClick={clearFilters} size="small" style={{ width: 92 }}>Limpar</Button>
                    </Space>
                </div>
            )
        },
        onFilter: (value: any, record: any) =>
            record[dataIndex]
                ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
                : "",
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.select(), 100);
            }
        }
    });
}
