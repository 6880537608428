const TOKEN = "token";
export const setAuthorization = (token: string): void => {
    localStorage.setItem(TOKEN, token);
}

export const removeAuthorization = (): void => {
    localStorage.removeItem(TOKEN);
}

export const getAuthorization = (): string | null => {
    return localStorage.getItem(TOKEN);
}

export const isAuthorization = (): boolean => {
    return localStorage.getItem(TOKEN) != null;
}
