import React from "react";
import { ReactComponent as Logo } from "@app/assets/img/logo.svg";

function HomeScreen() {
    return (
        <Logo/>
    );
}

export default HomeScreen;
