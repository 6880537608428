import axios from "axios";
import Env from "@app/env";
import { getAuthorization } from "@app/service/auth_service";

export default {
    setupInterceptors: (store: any) => {

        axios.defaults.baseURL = Env.serverURL
        axios.defaults.responseType = "json";
        axios.defaults.headers["Accept-Language"] = "pt";

        axios.interceptors.request.use(
            config => {
                const token = getAuthorization();
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            error => Promise.reject(error)
        )

        axios.interceptors.response.use(response => {
            return response;
        }, error => {

            if (error.response.status === 401) {
                store.dispatch({ type: "LOGOUT" });
            }

            if (error.response.status === 404) {
                //history.push("/not-found");
            }

            return Promise.reject(error);
        });
    }
};