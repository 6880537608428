import React from "react";

interface Props {
    currency?: string,
    decimals?: number
}

const FormatNumber: React.FC<Props> = (props) => {
    const { currency, decimals } = props;
    return (
        <>
            {isNaN(Number(props.children)) ? "" : Number(props.children).toFixed(decimals) + (currency ? " " + currency : "")}
        </>
    )
};

FormatNumber.defaultProps = {
    decimals: 2,
    currency: ""
};

export { FormatNumber };