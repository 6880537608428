import { combineReducers } from "redux";
import { ActionTypes, IProcessing } from "@app/reducers/types"

interface IUserListState extends IProcessing {
    list: any[]
}

const UserListState: IUserListState = {
    isProcessing: false,
    list: []
}

interface IUserDataState extends IProcessing {
    data: any
}

const UserDataState: IUserDataState = {
    isProcessing: false,
    data: null
}

const UserListReducer = (state = UserListState, action: ActionTypes): IUserListState => {
    switch (action.type) {
        case "GET_USER_ALL":
            return Object.assign({}, state, {
                isProcessing: true,
                list: []
            });
        case "GET_USER_ALL_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                list: action.payload.users
            });
        case "GET_USER_ALL_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                list: []
            });
        default:
            return state;
    }
}

const UserDataReducer = (state = UserDataState, action: ActionTypes): IUserDataState => {
    switch (action.type) {
        case "GET_USER_ID":
            return Object.assign({}, state, {
                isProcessing: true,
                data: null
            });
        case "GET_USER_ID_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                data: action.payload.user
            });
        case "GET_USER_ID_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                data: null
            });
        default:
            return state;
    }
}

const UserPositionsReducer = (state = UserListState, action: ActionTypes): IUserListState => {
    switch (action.type) {
        case "GET_USER_POSITIONS":
            return Object.assign({}, state, {
                isProcessing: true,
                list: []
            });
        case "GET_USER_POSITIONS_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                list: action.payload.positions
            });
        case "GET_USER_POSITIONS_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                list: []
            });
        default:
            return state;
    }
}

interface IUserHistoryState extends IUserListState, IUserDataState {
}

const UserHistoryState: IUserHistoryState = {
    list: [],
    data: null,
    isProcessing: false
}

const UserHistoryReducer = (state = UserHistoryState, action: ActionTypes): IUserHistoryState => {
    switch (action.type) {
        case "GET_USER_HISTORY":
            return Object.assign({}, state, {
                isProcessing: true,
                list: [],
                data: null
            });
        case "GET_USER_HISTORY_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                list: action.payload.history,
                data: {
                    profit: action.payload.profit,
                    total: action.payload.total
                }
            });
        case "GET_USER_HISTORY_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                list: [],
                data: null
            });
        default:
            return state;
    }
}

const UserTradersReducer = (state = UserListState, action: ActionTypes): IUserListState => {
    switch (action.type) {
        case "GET_USER_TRADERS":
            return Object.assign({}, state, {
                isProcessing: true,
                list: []
            });
        case "GET_USER_TRADERS_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                list: action.payload.userTraders
            });
        case "GET_USER_TRADERS_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                list: []
            });
        default:
            return state;
    }
}

const UserProtectionReducer = (state = UserDataState, action: ActionTypes): IUserDataState => {
    switch (action.type) {
        case "GET_USER_PROTECTION":
            return Object.assign({}, state, {
                isProcessing: true,
                data: null
            });
        case "GET_USER_PROTECTION_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                data: action.payload.protection
            });
        case "GET_USER_PROTECTION_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                data: null
            });
        default:
            return state;
    }
}

export default combineReducers({
    list: UserListReducer,
    data: UserDataReducer,
    positions: UserPositionsReducer,
    history: UserHistoryReducer,
    trader: UserTradersReducer,
    protection: UserProtectionReducer
});