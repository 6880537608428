import { combineReducers } from "redux";

import LoginReducer from "@app/reducers/login_reducer";
import LoggedReducer from "@app/reducers/logged_reducer";
import UserReducer from "@app/reducers/user_reducer";
import BrokerReducer from "@app/reducers/broker_reducer";

const appReducer = combineReducers({
    loginReducer: LoginReducer,
    loggedReducer: LoggedReducer,
    userReducer: UserReducer,
    brokerReducer: BrokerReducer
});

const rootReducer = (state: any, action: any) => {
    if (action.type === "LOGOUT") {
        state = undefined;
    }
    return appReducer(state, action);
}

export default rootReducer;
export type RootState = ReturnType<typeof rootReducer>
