import { combineReducers } from "redux";
import { ActionTypes, IProcessing } from "@app/reducers/types"

interface IBrokerListState extends IProcessing {
    list: any[]
}

const BrokerListState: IBrokerListState = {
    isProcessing: false,
    list: []
}

const BrokerListReducer = (state = BrokerListState, action: ActionTypes): IBrokerListState => {
    switch (action.type) {
        case "GET_BROKER_ALL":
            return Object.assign({}, state, {
                isProcessing: true,
                list: []
            });
        case "GET_BROKER_ALL_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                list: action.payload.accounts
            });
        case "GET_BROKER_ALL_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                list: []
            });
        default:
            return state;
    }
}

interface IBrokerDataState extends IProcessing {
    data: any
}

const BrokerDataState: IBrokerDataState = {
    isProcessing: false,
    data: null
}

const BrokerDataReducer = (state = BrokerDataState, action: ActionTypes): IBrokerDataState => {
    switch (action.type) {
        case "GET_BROKER_ACCOUNT":
            return Object.assign({}, state, {
                isProcessing: true,
                data: null
            });
        case "GET_BROKER_ACCOUNT_SUCCESS":
            return Object.assign({}, state, {
                isProcessing: false,
                data: action.payload.account
            });
        case "GET_BROKER_ACCOUNT_FAIL":
            return Object.assign({}, state, {
                isProcessing: false,
                data: null
            });
        case "GET_BROKER_ACCOUNT_CLEAR":
            return Object.assign({}, state, {
                data: null
            });
        default:
            return state;
    }
}

export default combineReducers({
    list: BrokerListReducer,
    data: BrokerDataReducer
});