import React from "react";
import { connect } from "react-redux";
import { Table, Button, PageHeader } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { RouteComponentProps } from "react-router-dom"
import { RootState } from "@app/reducers";
import { bindActionCreators, Dispatch } from "redux";
import { getAllUserAction } from "@app/actions/user_action";
import { sortTable, filterBasicTable, filterAdvancedTable } from "@app/utils";
import { appRender, connectedRender, enabledRender } from "@app/constants/list";

const mapStateToProps = (state: RootState) => ({
    user: state.userReducer.list
})

const mapDispatchToProps = (dispatch: Dispatch) =>
    bindActionCreators(
        {
            getAllUserAction: getAllUserAction
        },
        dispatch
    );

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps> & RouteComponentProps;


const UserListScreen: React.FC<Props> = (props) => {
    const { getAllUserAction, user, history } = props;

    React.useEffect(() => {
        getAllUserAction();
    }, [getAllUserAction]);

    const onDetails = (id: string) => {
        history.push("/user/detail", { userId: id });
    }

    return (
        <PageHeader ghost={false} title="Usuários">
            <Table loading={user.isProcessing} size="small" dataSource={user.list} pagination={false} bordered rowKey="id" scroll={{ x: 1000 }}>
                <Table.Column title="Nome" dataIndex="name" {...sortTable("name")} {...filterAdvancedTable("name")} />
                <Table.Column title="Email" dataIndex="email" {...sortTable("email")} {...filterAdvancedTable("email")} />
                <Table.Column width={100} title="Conectado" dataIndex="connected" {...filterBasicTable("connected", connectedRender.list)}
                              render={connectedRender.render} />
                <Table.Column width={50} title="APP" dataIndex="firstLogin" {...filterBasicTable("firstLogin", appRender.list)}
                              render={appRender.render} />
                <Table.Column width={80} title="Status" dataIndex="enabled" {...filterBasicTable("enabled", appRender.list)}
                              render={enabledRender.render} />
                <Table.Column width={100} render={(row) =>
                    <Button type="link" icon={<EditOutlined />} onClick={() => onDetails(row.id)}>Detalhes</Button>}
                />
            </Table>
        </PageHeader>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(UserListScreen);
