import axios from "axios";
import { handlerError } from "@app/utils"
import { AppThunk } from "@app/actions/types";

export const getBrokerListAction = (company: string, startTime: string, endTime: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_BROKER_ALL" });
        const res = await axios.get(`broker/${company}`, { params: { startTime: startTime, endTime: endTime } });
        console.log(res)
        dispatch({ type: "GET_BROKER_ALL_SUCCESS", payload: res.data });
    } catch (ex) {
        dispatch({ type: "GET_BROKER_ALL_FAIL", payload: handlerError(ex) })
    }
}

export const getBrokerSearchByAccountAction = (account: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_BROKER_ACCOUNT" });
        const res = await axios.get(`broker/search/${account}`);
        console.log(res)
        dispatch({ type: "GET_BROKER_ACCOUNT_SUCCESS", payload: res.data });
    } catch (ex) {
        dispatch({ type: "GET_BROKER_ACCOUNT_FAIL", payload: handlerError(ex) })
    }
}