import axios from "axios";
import { notification } from "antd";
import { handlerError } from "@app/utils"
import { AppThunk } from "@app/actions/types";

export const getAllUserAction = (): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_ALL" });
        const { data } = await axios.get("/user");
        dispatch({ type: "GET_USER_ALL_SUCCESS", payload: data });
    } catch (ex) {
        dispatch({ type: "GET_USER_ALL_FAIL", payload: handlerError(ex) })
    }
}

export const getByIdUserAction = (id: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_ID" });
        const { data } = await axios.get(`/user/${id}`);
        dispatch({ type: "GET_USER_ID_SUCCESS", payload: data });
    } catch (ex) {
        dispatch({ type: "GET_USER_ID_FAIL", payload: handlerError(ex) })
    }
}

export const getByIdUserPositionsAction = (id: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_POSITIONS" });
        const res = await axios.get(`/user/${id}/positions`);
        dispatch({
            type: "GET_USER_POSITIONS_SUCCESS",
            payload: res.data
        });
    } catch (ex) {
        dispatch({ type: "GET_USER_POSITIONS_FAIL", payload: handlerError(ex) })
    }

};

export const getByIdUserHistoryAction = (id: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_HISTORY" });
        const res = await axios.get(`/user/${id}/history`);
        dispatch({
            type: "GET_USER_HISTORY_SUCCESS",
            payload: res.data
        });
    } catch (ex) {
        dispatch({ type: "GET_USER_HISTORY_FAIL", payload: handlerError(ex) })
    }
};

export const getByIdUserTradersAction = (id: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_TRADERS" });
        const res = await axios.get(`/user/${id}/trader`);
        dispatch({
            type: "GET_USER_TRADERS_SUCCESS",
            payload: res.data
        });
    } catch (ex) {
        dispatch({ type: "GET_USER_TRADERS_FAIL", payload: handlerError(ex) })
    }
};

export const getByIdUserProtectionAction = (id: string): AppThunk => async (dispatch) => {
    try {
        dispatch({ type: "GET_USER_PROTECTION" });
        const res = await axios.get(`/user/${id}/protection`);
        dispatch({
            type: "GET_USER_PROTECTION_SUCCESS",
            payload: res.data
        });
    } catch (ex) {
        dispatch({ type: "GET_USER_PROTECTION_FAIL", payload: handlerError(ex) })
    }
};

export const resetPasswordByIdUserAction = (id: string): AppThunk => async (dispatch) => {
    try {
        await axios.post(`/user/${id}/reset/password`, {});
    } catch (ex) {
        handlerError(ex)
    }
};

export const clearAccountByIdUserAction = (id: string): AppThunk => async (dispatch) => {
    try {
        await axios.post(`/user/${id}/clear/account`, {});
    } catch (ex) {
        handlerError(ex)
    }
};